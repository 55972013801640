import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const reproche = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Reproche" />
    <h3 className='underline-title'>Reproche</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Ta danse rotative déséquilibre leurs maisons en ruine<br />
        Tes lueurs empruntées n'éclairent pas leurs taudis de nuit<br />
        Ton parfum fugace ne résiste pas au relent de leur ordure<br />
        Tes cheveux d'Absalon ne balaient pas leurs souillures rebelles<br />
        </p>
        <p>
        Tu es la mère-crocodile<br />
        Abandonnant ses oeufs a la couvee du sable riverain<br />
        Tu es la mère-serpent<br />
        N'allaitant pas ses petits à la soif saharienne<br />
        </p>
        <p>
        Tes enfants au coeurs de cobra<br />
        Tes enfants à la conscience faisandée<br />
        Tes enfants aux mains panachées de zakriza(*)<br />
        Tes enfants aux membres-ventouses<br />
        Tes enfants charognards en course mutine<br />
        </p>
        <p>
        Leur vie cordée au fil de leur voix<br />
        Ils crient plus fort qu'une sirène <br />
        Unique filon pour percer le nuage de leur boue <br />
        Leur vacarme est plus fort que la rage<br />
        Ils sont pires qu'un idiot au coeur de mutisme<br />
        Dommage qu'ils ne doivent que criailler<br />
        Ton bateau en a jeté l'ancre dans leur coeur<br />
        Leur coeur l'ancrage de ton ancre<br />
        </p>
        <p>
        Apprends-leur plutot la constipation des mots <br />
        Et la diarrhée d'actions<br />
        Secret sûr d'un travail rentable<br />
        </p>
    </div>
    <hr/>
    <p className="font-italic">
        (*) Araignée
      </p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default reproche
